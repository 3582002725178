.search-results-page,
.no-results-page {
    margin-left: 30px;
    padding-top: 120px;
}

.go-back {
    @include transition(0.3s);

    &:hover {
        margin-left: -10px;
        @include transition(0.3s);
        cursor: pointer;
    }
}

//searchbar
.searchbar-input {
    position: relative;
}

.searchbar-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
}

//no results styling
.no-results-wrapper {
    background: $branco;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.12);
    border-radius: $radius-20;
    padding: $card-content-padding;
    display: block;
    margin: 0 auto 150px;
}

//MEDIA QUERIES -------------------------------------
@include large {
    .no-results-wrapper {
        width: 40%;
    }
}

@include max-large {
    .no-results-page {
        margin-right: 30px;
    }
}

@include small {
    .search-results-page,
    .no-results-page {
        margin-left: 50px;
    }
}
