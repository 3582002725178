//PADDINGS ------------------------------
.padding10-10 {
    padding: 10px;
}

.padding20-20 {
    padding: 20px;
}

.padding30-30 {
    padding: 30px;
}

.padding-bottom20 {
    padding-bottom: 20px;
}

.padding-bottom30 {
    padding-bottom: 30px;
}

.padding-top20 {
    padding-top: 20px;
}

.padding-top30 {
    padding-top: 30px;
}

.padding-top40 {
    padding-top: 40px;
}

.padding-top70 {
    padding-top: 70px;
}

.padding-left10 {
    padding-left: 10px;
}

.padding-left20 {
    padding-left: 20px;
}

.padding-left30 {
    padding-left: 30px;
}

.padding-left50 {
    padding-left: 50px;
}

.padding-right30 {
    padding-right: 30px;
}

.padding-right50 {
    padding-right: 50px;
}

//MARGINS
//------------------------------------------------------
//TOP
.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-70 {
    margin-top: 70px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-90 {
    margin-top: 90px;
}

.mt-100 {
    margin-top: 100px;
}

//BOTTOM
.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mb-90 {
    margin-bottom: 90px;
}

.mb-100 {
    margin-bottom: 100px;
}

//RIGHT
.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

//BACKGROUND COLORS ---------------------------------
.background-green {
    background-color: $hortela;
}

.background-orange {
    background-color: $laranja;
}

.background-red {
    background-color: $toranja;
}

.background-blue {
    background-color: $azul-escuro;
}

.background-light-brown {
    background-color: $anis-chocolate;
}

.background-dark-brown {
    background-color: $chocolate;
}

.background-dark-green {
    background-color: $eucalipto;
}

.background-purple {
    background-color: $vinho-porto;
}

//TEXT COLORS ----------------------------------------
.white-text {
    color: $branco;
}

.grey-text {
    color: $cinza;
}

.blue-text {
    color: $azul-escuro;
}

.orange-text {
    color: $laranja;
}

.green-text {
    color: $hortela;
}

.light-brown-text {
    color: $anis-chocolate;
}

.dark-brown-text {
    color: $chocolate;
}

.dark-green-text {
    color: $eucalipto;
}

.purple-text {
    color: $vinho-porto;
}

.red-text {
    color: $toranja;
}

//DISPLAYS ------------------------------------------------------
.inline {
    display: inline-block;
}

//POSITION ------------------------------------------------------
.position-fixed {
    position: fixed;
}

//ROTATIONS -----------------------------------------------------
.rotate10 {
    transform: rotate(10deg);
}

.rotate20 {
    transform: rotate(20deg);
}

.rotate30 {
    transform: rotate(30deg);
}

.rotate40 {
    transform: rotate(40deg);
}

.rotate50 {
    transform: rotate(50deg);
}

.rotate60 {
    transform: rotate(60deg);
}

.rotate70 {
    transform: rotate(70deg);
}

.rotate80 {
    transform: rotate(80deg);
}

.rotate-20 {
    transform: rotate(-20deg);
}

//TEXT --------------------------------------------------
.text-centered {
    text-align: center;
}

//SVG PATTERNS --------------------------------------------------
//these are the variables used for the svg functions inside file _functions.scss
$svg-original: svg-stripes($laranja);
$svg-hortela: svg-stripes($hortela);
$svg-anis: svg-stripes($anis-chocolate);
$svg-eucalipto: svg-stripes($eucalipto);
$svg-vinho-porto: svg-stripes($vinho-porto);
$svg-toranja: svg-stripes($toranja);
$svg-chocolate-jam: svg-stripes($chocolate);
$svg-original-berry: svg-solid($laranja);
$svg-chocolate: svg-solid($chocolate);

//these are the classes that match the svg variables
.svg-original {
    background-image: url(svg-encode($svg-original));
}

.svg-hortela {
    background-image: url(svg-encode($svg-hortela));
}

.svg-anis {
    background-image: url(svg-encode($svg-anis));
}

.svg-eucalipto {
    background-image: url(svg-encode($svg-eucalipto));
}

.svg-vinho-porto {
    background-image: url(svg-encode($svg-vinho-porto));
}

.svg-toranja {
    background-image: url(svg-encode($svg-toranja));
}

.svg-original-berry {
    background-image: url(svg-encode($svg-original-berry));
}

.svg-chocolate-jam {
    background-image: url(svg-encode($svg-chocolate-jam));
}

.svg-chocolate {
    background-image: url(svg-encode($svg-chocolate));
}
