//GRID FOR THE ENTIRE PAGE ----------------------------------------
@include large {
    .breadcrumb-container {
        padding-left: 50px;
        padding-top: 120px;
    }

    .horizontal-menu {
        display: none;
    }

    .product-details-grid {
        @include grid-columns(1fr 1fr, 30px);
        grid-template-areas:
            'sidebar     left                  right'
            'sidebar     details               details'
            'sidebar     suggestions-heading   suggestions-heading'
            'sidebar     suggested-products    suggested-products';

        .sidebar-menu {
            grid-area: sidebar;
        }

        .left-content-container {
            grid-area: left;
            padding-top: 30px;
            margin-bottom: 50px;
        }

        .right-content-container {
            grid-area: right;
            width: 490px;
            margin-bottom: 50px;
            padding-right: 50px;
        }

        .details {
            grid-area: details;
        }

        .details-title {
            margin-left: -335px; //margin for pulling left the details title colored bar

            h2 {
                padding-left: 330px;
            }
        }

        .cards {
            display: flex;
            flex-direction: row;
            margin-top: -50px; //margin for overlapping cards with details title
        }

        .card-ingridients-container {
            width: 520px;
            padding-right: 20px;
            grid-area: card-ingridients-container;
        }

        .card-nutritional-container {
            padding-left: 20px;
            width: 540px;
            grid-area: card-nutritional-container;
        }

        .suggestions-heading {
            grid-area: suggestions-heading;
            padding-left: 10px;
            padding-top: 70px;
        }

        //suggested product images
        //the grid for these images is styled in blocs/_discoverProducts.scss
        .discover-section-grid {
            grid-area: suggested-products;
        }
    }
}

//max 576px screen sizes
@include max-small {
    .left-content-container,
    .product-detail-image {
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .details-title {
        h2 {
            padding-left: 30px;
        }
    }
}

//max 768px screen sizes
@include max-medium {
    .product-details-grid {
        grid-template-areas:
            'left'
            'right'
            'details'
            'card-ingridients-container'
            'card-nutritional-container'
            'suggestions-heading'
            'suggested-products';

        .left-content-container,
        .right-content-container,
        .card-ingridients-container,
        .card-nutritional-container,
        .suggestions-heading,
        .discover-section-grid {
            padding-left: 30px;
            padding-right: 30px;
        }

        .details-title {
            h2 {
                padding-left: 30px;
            }
        }

        .card-ingridients-container {
            margin-top: -20px;
        }
    }
}

//max 992px screen sizes
@include max-large {
    .sidebar {
        display: none;
    }

    .horizontal-menu {
        display: inline;
    }

    .breadcrumb-container {
        padding-top: 30px;
        padding-left: 30px;
    }

    .left-content-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .solid-pattern-container,
    .stripes-pattern-container {
        display: none;
    }

    .right-content-container,
    .card-ingridients-container,
    .card-nutritional-container {
        padding-bottom: 40px;
    }

    .left-content-container,
    .right-content-container,
    .card-ingridients-container,
    .card-nutritional-container {
        padding-left: 50px;
        padding-right: 50px;
    }

    .details-title {
        h2 {
            padding-left: 60px;
        }
    }

    .card-ingridients-container {
        margin-top: -20px;
    }
}

@include min-medium-max-large {
    .product-details-grid {
        padding-top: 20px;
        grid-template-areas:
            'sidebar    left'
            'sidebar    right'
            'sidebar    details-title'
            'sidebar    card-ingridients-container'
            'sidebar    card-nutritional-container'
            'sidebar    suggestions-heading'
            'sidebar    suggested-products';
    }

    .details-title {
        grid-area: details-title;
    }

    .cards {
        display: flex;
        flex-direction: column;
        margin-top: -50px;
    }
}

//SVG PATTERN CONTAINERS
.base-pattern-container {
    background-position: center;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 250px;
    width: 250px;
    position: relative;
}

//first toranja svg pattern
.stripes-pattern-container {
    @extend .base-pattern-container;
    margin-top: -200px;
    margin-left: -100px;
}

//second toranja svg pattern
.stripes-pattern-container2 {
    @extend .base-pattern-container;
    margin-top: -100px;
    margin-left: -100px;
}

.solid-pattern-container {
    @extend .base-pattern-container;
    margin-top: -150px;
    margin-left: -400px;
}

//second toranja svg pattern
.solid-pattern-container2 {
    @extend .base-pattern-container;
    margin-top: -160px;
    margin-left: -150px;
}

.product-details-grid {
    .btn {
        margin-right: 20px;
    }
}

//display brand's logos when hovering product images
$product-logos: (
    physalina: url('../../images/logos/physalina-logo.svg'),
    floryptus: url('../../images/logos/floryptus-logo.svg'),
    qpetisco: url('../../images/logos/qpetisco-logo.svg'),
);

@each $brand, $logo in $product-logos {
    .#{$brand}-link {
        position: relative;
        display: block;

        &:hover .image-hover {
            background-image: $logo;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
        }
    }
}

//logo images that are displayed when hovering products
.image-hover {
    display: none;
    position: absolute;
    top: 45%;
    left: 45%;
    transform: translate(-45%, -45%);
    height: 50px;
    width: 250px;
    z-index: 2;
}
