//GRIDS -------------------------------------------------
//columns + rows
@mixin grid-container($columns, $rows, $column-gap: 0, $row-gap: 0) {
    display: grid;
    grid-template-columns: $columns;
    grid-template-rows: $rows;
    grid-column-gap: $column-gap;
    grid-row-gap: $row-gap;
}

//only columns
@mixin grid-columns($columns, $column-gap: 0, $row-gap: 0) {
    display: grid;
    grid-template-columns: $columns;
    grid-column-gap: $column-gap;
    grid-row-gap: $row-gap;
}
//---------------------------------------------------------

//MEDIA QUERIES -------------------------------------------
@mixin extra-small {
    @media (min-width: #{$screen-extra-small}) {
        @content;
    }
}

@mixin max-extra-small {
    @media (max-width: #{$screen-extra-small}) {
        @content;
    }
}

@mixin small {
    @media (min-width: #{$screen-small}) {
        @content;
    }
}

@mixin max-small {
    @media (max-width: #{$screen-small}) {
        @content;
    }
}

@mixin medium {
    @media (min-width: #{$screen-medium}) {
        @content;
    }
}

@mixin max-medium {
    @media (max-width: #{$screen-medium}) {
        @content;
    }
}

@mixin large {
    @media (min-width: #{$screen-large}) {
        @content;
    }
}

@mixin max-large {
    @media (max-width: #{$screen-large}) {
        @content;
    }
}

@mixin extra-large {
    @media (min-width: #{$screen-extra-large}) {
        @content;
    }
}

@mixin max-extra-large {
    @media (max-width: #{$screen-extra-large}) {
        @content;
    }
}

@mixin min-medium-max-large {
    @media (min-width: $screen-medium) and (max-width: $screen-large) {
        @content;
    }
}

@mixin min-large-max-extra-large {
    @media (min-width: $screen-large) and (max-width: $screen-extra-large) {
        @content;
    }
}

//---------------------------------------------------------

//RESPONSIVE TYPOGRAPHY -----------------------------------
@mixin calc-font-size($min-vw, $max-vw, $min-font-size, $max-font-size) {
    font-size: calc(
        #{$min-font-size}rem +
            ((100vw - #{$min-vw}rem) / (#{$max-vw} - #{$min-vw})) *
            (#{$max-font-size} - #{$min-font-size})
    );
}

@mixin calc-basic-font-size($font-size, $vw) {
    font-size: calc($font-size + $vw);
}

//---------------------------------------------------------
//TRANSITION
@mixin transition($time) {
    transition: all $time;
}
