.explore-section {
    background-color: $azul-escuro;
    height: 130px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    i {
        @include calc-font-size(50, 100, 1.7, 1.9);
        padding-left: 15px;
        color: $branco;
    }
}

@include large {
    .explore-section {
        .link {
            text-decoration: none;
            transition: all 0.9s;

            &:hover {
                margin-left: 20px;
                transition: all 0.3s;

                i,
                .h3 {
                    color: $cinza;
                    transition: all 0.3s;
                }
            }
        }
    }
}

@include max-small {
    .explore-section {
        height: 90px;
    }
}
