// COLORS --------------------------------------------------------
$laranja: #ff7800;
$azul-escuro: #0f2043;
$cinza: #b9b9b9;
$branco: #ffffff;

$toranja: #ef1e43;
$vinho-porto: #b40091;
$anis-chocolate: #d45200;
$chocolate: #6a2e1a;
$hortela: #00d756;
$eucalipto: #4a8d5a;

// BORDER-RADIUS ------------------------------------------------
$radius-5: 5px;
$radius-10: 10px;
$radius-15: 15px;
$radius-20: 20px;

// MEASURES -----------------------------------------------------
$card-content-padding: 30px;
$card-about-padding: 40px;

//SCREEN SIZES --------------------------------------------------
$screen-extra-small: 320px;
$screen-small: 576px;
$screen-medium: 768px;
$screen-large: 992px;
$screen-extra-large: 1200px;

//SVG PATTERN SIZES ---------------------------------------------
$pattern-size-small: 100px;
$pattern-size-medium: 150px;
$pattern-size-large: 200px;
$pattern-size-extra-large: 400px;

//ARRAY $product-colors for changing color when hovering product
//this is part of the each function inside file _productList.scss
$product-colors: (
    original: $laranja,
    hortela: $hortela,
    anis: $anis-chocolate,
    eucalipto: $eucalipto,
    vinho-porto: $vinho-porto,
    toranja: $toranja,
    original-berry: $laranja,
    chocolate: $chocolate,
);
