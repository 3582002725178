//SCREEN SIZES BIGGER THAN 1200px -----------------------------------------
@include extra-large {
    .headings-wrapper-about {
        h3 {
            margin: 0 300px 50px 300px;
        }
    }

    .cards-our-brands-wrapper {
        @include grid-columns(1fr, 50px);
        margin-left: 50px;
        margin-right: 50px;
    }

    .cards-about-wrapper {
        @include grid-columns(1fr 1fr, 50px);
        margin: 0 50px 150px 50px;
    }

    .about-brand-pattern {
        width: 200px;
        height: 200px;
    }

    .discover-our-brands {
        margin-bottom: 150px;
    }

    .please-drag {
        display: none;
    }

    .our-history-card {
        animation-name: animate-card;
        animation-duration: 5s;
        animation-fill-mode: forwards;
        margin-bottom: 100px;
    }

    //keyframes for card animation
    @keyframes animate-card {
        from {
            transform: translateY(0);
        }

        to {
            transform: translateY(150px);
            animation-play-state: paused;
        }
    }
}

//SCREEN SIZES BIGGER THAN 992px AND SMALLER THAN 1200px -------------------------------
@include min-large-max-extra-large {
    .headings-wrapper-about {
        h3 {
            margin: 0 50px 50px 50px 50px;
        }
    }

    .cards-about-wrapper,
    .cards-our-brands-wrapper {
        @include grid-columns(1fr, 50px);
        margin-left: 50px;
        margin-right: 50px;
    }

    .cards-about-wrapper {
        margin-bottom: 130px;
    }

    .cards-our-brands-wrapper {
        img {
            width: 80%;
        }
    }

    .about-brand-pattern {
        width: 200px;
        height: 200px;
    }

    .discover-our-brands {
        margin-bottom: 150px;
    }

    .please-drag {
        display: none;
    }
}

//SCREEN SIZES BETWEEN 768px AND 992px SCREEN SIZES -----------------------------------------
@include min-medium-max-large {
    .headings-wrapper-about {
        h3 {
            margin-right: 50px;
            margin-left: 50px;
        }
    }

    .cards-about-wrapper,
    .cards-our-brands-wrapper {
        @include grid-columns(1fr, 50px, 50px);
        margin-left: 50px;
        margin-right: 50px;
    }

    .cards-about-wrapper {
        margin-bottom: 100px;
    }

    .our-process-card {
        margin-bottom: 30px;
    }

    .logo-card {
        height: 200px;

        img {
            width: 90%;
        }
    }

    .about-brand-pattern {
        width: 180px;
        height: 180px;
    }

    .discover-our-brands {
        margin-bottom: 100px;
    }

    .please-drag {
        float: right;
        margin-right: 50px;
        margin-top: -25px;
    }
}

//SCREEN SIZES SMALLER THAN 768px -----------------------------------------
@include max-medium {
    .headings-wrapper-about {
        h2 {
            margin-right: 30px;
        }

        h3 {
            margin-right: 30px;
            margin-bottom: 30px;
        }

        .our-process-card {
            margin-bottom: 30px;
        }
    }

    .cards-about-wrapper,
    .cards-our-brands-wrapper {
        @include grid-columns(1fr, 0px, 30px);
        margin-left: 30px;
        margin-right: 30px;
    }

    .cards-about-wrapper {
        margin-bottom: 80px;
    }

    .logo-card {
        img {
            width: 90%;
        }
    }

    .about-brand-pattern {
        width: 180px;
        height: 180px;
    }

    .discover-our-brands {
        margin-bottom: 80px;
    }

    .please-drag {
        float: right;
        margin-right: 50px;
        margin-top: -25px;
    }
}

//SCREEN SIZES SMALLER THAN 576px -----------------------------------------
@include max-extra-small {
    .cards-about-wrapper,
    .cards-our-brands-wrapper {
        @include grid-columns(1fr, 0px, 30px);
        margin-left: 30px;
        margin-right: 30px;
    }

    .discover-our-brands {
        margin-bottom: 80px;

        h3 {
            margin-left: 30px;
            margin-right: 30px;
            text-align: center;
        }
    }

    .please-drag {
        float: right;
        margin-right: 30px;
        margin-top: -20px;
    }
}

//GENERAL STYLES FOR ALL SCREEN SIZES -------------------------------------
.headings-wrapper-about {
    padding-top: 120px;
    margin-left: 30px;

    h1,
    h3 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

.cards-our-brands-wrapper {
    img {
        display: block;
        margin: 0 auto;
    }
}

//svg patterns
.about-brand-pattern {
    background-position: center;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    display: block;
    margin: 0 auto;
}

.card-margin {
    margin-top: -110px;
}

.discover-our-brands {
    h3 {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
