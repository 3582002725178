//PADDINGS FOR THE PAGE ITSELF ------------------------------
.jam-list,
.berry-list {
    padding-left: 30px;
    padding-top: 120px;
}

.product-usage-suggestions {
    @include grid-columns(400px 500px, 50px);
}

@include small {
    .jam-list,
    .berry-list {
        padding-left: 50px;
    }
}

//-----------------------------------------------------------
//TITLE & SUBTITLE SECTION ----------------------------------
.title-subtitle {
    width: 48%;
}

@include max-medium {
    .title-subtitle {
        width: 90%;
    }

    .discover-section-grid {
        row-gap: 50px;
        justify-items: center;
    }

    .product-usage-suggestions {
        @include grid-columns(1fr, 50px);
        margin-right: 30px;
    }
}

//-----------------------------------------------------------
//MAIN GRID FOR PRODUCTS
.product {
    &-list-grid {
        @include grid-columns(repeat(auto-fill, minmax(340px, 1fr)));
        justify-items: center;
        grid-gap: 80px 25px;
    }

    &-image {
        width: 80%;
        height: auto;
        transition: all 0.3s;

        &:hover {
            transform: scale(1.1);
        }
    }

    &-name,
    &-category {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $azul-escuro;
        text-align: center;
    }
}

//-----------------------------------------------------------
//BUTTON FOR EXTERNAL LINK TO GO TO ONLINE STORE
.online-store-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;
}

//-----------------------------------------------------------

//EACH FUNCTION FOR HOVER/FOCUS BEHAVIORS ------------------------------
//the array "$product-colors" is inside _variables.scss
@each $product, $color in $product-colors {
    .#{$product}-link {
        &:hover .product-name,
        &:focus .product-name {
            color: $color;
        }
    }
}
