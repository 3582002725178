::-webkit-scrollbar {
    width: 8px;

    &-track {
        background: #eaeff0;
    }

    &-thumb {
        background: $azul-escuro;
        border-radius: 5px;
    }
}
