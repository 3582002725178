.landing-header {
    width: 100%;
}

//SCREEN SIZES BIGGER THAN 768px ----------------------------------------
@include medium {
    .landing-header-container {
        h3 {
            padding-left: 60px;
        }

        .landing-header {
            height: 150px;
        }
    }
}

//SCREEN SIZES SMALLER THAN 768px ----------------------------------------
@include max-medium {
    .landing-header-container {
        h3 {
            padding-left: 30px;
        }

        .landing-header {
            height: 130px;
        }
    }
}
