//SCREEN SIZES BIGGER THAN 992px -----------------------------------------
@include large {
    .breadcrumb-wrapper {
        padding-left: 50px;
        padding-top: 120px;
    }

    .headings-wrapper,
    .suggestions-heading,
    .discover-section-grid {
        margin-left: 50px;
    }

    .suggestions-heading {
        .general-info {
            width: 50%;
        }
    }

    .categories-wrapper {
        margin-top: 80px;

        .inline-jams-grid {
            @include grid-columns(repeat(6, 200px));
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 30px;

            img {
                width: 100%;
            }
        }

        .explore-jams {
            margin-top: -6%;
            margin-bottom: 100px;
        }

        .inline-berries-grid {
            img {
                width: 300px;
            }
        }

        .explore-berries {
            margin-top: -8%;
            margin-bottom: 60px;
        }
    }

    //jam pattern that is displayed when hovering image
    .inline-jams-hover {
        display: none;
        position: absolute;
        top: -55%;
        left: 65%;
        transform: translate(-55%, 65%);
        height: 90px;
        width: 90px;
        z-index: 2;
    }

    //berry pattern that is displayed when hovering image
    .inline-berries-hover {
        display: none;
        position: absolute;
        top: -35%;
        left: 50%;
        transform: translate(-35%, 50%);
        height: 70px;
        width: 70px;
        z-index: 2;
    }
}

//SCREEN SIZES BETWEEN MEDIUM AND LARGE SIZES -----------------------------------------
@include min-medium-max-large {
    .breadcrumb-wrapper {
        padding-top: 120px;
        padding-left: 30px;
    }

    .headings-wrapper,
    .suggestions-heading,
    .discover-section-grid {
        margin-left: 30px;
    }

    .categories-wrapper {
        .inline-jams-grid {
            display: flex;
            flex-direction: row;
            overflow-x: auto;
            overflow-y: hidden;

            img {
                width: 300px;
            }
        }

        .explore-jams {
            margin-top: -15%;
            margin-bottom: 80px;
        }

        .inline-berries-grid {
            img {
                width: 300px;
            }
        }

        .explore-berries {
            margin-top: -8%;
            margin-bottom: 100px;
        }
    }
}

//SCREEN SIZES SMALLER THAN 768px -----------------------------------------
@include max-medium {
    .breadcrumb-wrapper {
        padding-left: 50px;
        padding-top: 120px;
    }

    .headings-wrapper,
    .suggestions-heading,
    .discover-section-grid {
        margin-left: 50px;
    }

    .inline-jams-grid {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        overflow-y: hidden;
        margin-top: 20px;

        img {
            width: 230px;
        }
    }

    .explore-jams,
    .explore-berries {
        margin-top: -20%;
        margin-bottom: 80px;
    }

    .inline-berries-grid {
        overflow: hidden;
        -ms-overflow-style: none;

        img {
            width: 200px;
        }

        ::-webkit-scrollbar {
            display: none;
        }
    }
}

@include max-extra-small {
    .inline-jams-grid {
        img {
            width: 150px;
        }
    }
    .inline-berries-grid {
        margin-left: 20px;
        margin-right: 20px;

        img {
            width: 160px;
        }
    }
}

//GENERAL STYLES FOR ALL SCREEN SIZES --------------------------------
.categories-wrapper {
    .inline-berries-grid {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .explore-berries,
    .explore-jams {
        position: relative;
        z-index: 2;
    }

    .inline-jams-grid {
        ::-webkit-scrollbar {
            display: none;
        }
    }
}

//display product patterns when hovering images
$product-patterns: (
    original: url('../../images/patterns/original.svg'),
    hortela: url('../../images/patterns/hortela.svg'),
    anis: url('../../images/patterns/anis.svg'),
    eucalipto: url('../../images/patterns/eucalipto.svg'),
    vinhoporto: url('../../images/patterns/vinhoporto.svg'),
    toranja: url('../../images/patterns/toranja.svg'),
    original-berry: url('../../images/patterns/Baga_original.svg'),
    chocolate: url('../../images/patterns/chocolate.svg'),
);

@each $product, $pattern in $product-patterns {
    .#{$product}-inline {
        position: relative;
        display: block;
        transition: all 0.3s;

        &:hover {
            transform: scale(1.1);
            transition: all 0.3s;
        }

        &:hover .inline-jams-hover,
        &:hover .inline-berries-hover {
            background-image: $pattern;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
        }
    }
}
