//SCREEN SIZES BIGGER THAN 1200px ---------------------------------------
@include extra-large {
    .introduction-text {
        margin-left: 50px;

        h1 {
            width: 70%;
        }

        .description {
            width: 50%;
        }

        .landing-pattern {
            width: $pattern-size-extra-large;
            height: $pattern-size-extra-large;
            top: -60px;
            right: -60px;
        }
    }

    .banner-container {
        padding-top: 100px;

        img {
            margin-right: -90px;
        }
    }

    //product cards grid
    .product-cards-grid {
        @include grid-columns(repeat(3, 1fr), 50px, 100px);
        margin: 0 50px;
    }

    //grid for all the flavours
    .flavours-section {
        &-grid {
            @include grid-columns(repeat(3, 1fr), 0, 20px);

            .flavour {
                &-margin-top {
                    margin-top: 150px;
                }

                &-pattern {
                    width: $pattern-size-large + 100px;
                    height: $pattern-size-large + 100px;
                }

                .hortela-flavour,
                .vinho-porto-flavour,
                .anis-flavour {
                    width: 400px;
                }
            }
        }
    }

    .newsletter-input-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 45px;
    }
}

//SCREEN SIZES BIGGER THAN 992px AND SMALLER THAN 1200px -----------------
@include min-large-max-extra-large {
    .introduction-text {
        margin-left: 50px;

        h1 {
            width: 70%;
        }

        .description {
            width: 50%;
        }

        .landing-pattern {
            width: $pattern-size-extra-large;
            height: $pattern-size-extra-large;
            top: -60px;
            right: -60px;
        }
    }

    .banner-container {
        padding-top: 100px;

        img {
            margin-right: -90px;
        }
    }

    //PRODUCT CARDS LAYOUT GRID ------------------------------------------
    .product-cards-grid {
        @include grid-columns(repeat(2, 1fr), 50px, 100px);
        margin: 0 50px;
    }

    .flavours-section {
        &-grid {
            @include grid-columns(repeat(2, 1fr), 0px, 120px);

            .flavour {
                &-pattern {
                    width: $pattern-size-large + 100px;
                    height: $pattern-size-large + 100px;
                }

                .hortela-flavour,
                .vinho-porto-flavour,
                .anis-flavour {
                    width: 400px;
                }
            }
        }
    }

    .newsletter-input-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 45px;
    }
}

//SCREEN SIZES BETWEEN 768px AND 992px SCREEN SIZES ----------------------
@include min-medium-max-large {
    .introduction-text {
        margin-left: 50px;

        h1 {
            width: 70%;
        }

        .description {
            width: 50%;
        }

        .landing-pattern {
            width: $pattern-size-extra-large;
            height: $pattern-size-extra-large;
            top: -90px;
            right: -90px;
        }
    }

    .banner-container {
        padding-top: 100px;

        img {
            margin-right: -90px;
        }
    }

    .product-cards-grid {
        @include grid-columns(500px, 0, 80px);
        margin: 0 100px;
    }

    .flavours-section {
        margin-right: 20px;

        &-grid {
            @include grid-columns(repeat(2, 1fr), 20px, 120px);

            .flavour {
                &-pattern {
                    width: $pattern-size-large + 100px;
                    height: $pattern-size-large + 100px;
                }

                .hortela-flavour,
                .vinho-porto-flavour,
                .anis-flavour {
                    width: 300px;
                }
            }
        }
    }

    .newsletter-input-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 45px;
    }
}

//SCREEN SIZES SMALLER THAN 768px ----------------------------------------
@include max-medium {
    .introduction-text {
        margin-left: 30px;

        h1,
        .description {
            width: 90%;
        }

        .landing-pattern {
            width: $pattern-size-large;
            height: $pattern-size-large;
            top: 20px;
            right: -20px;
        }
    }

    .banner-container {
        padding-top: 10px;

        img {
            margin-right: -150px;
            transform: scale(0.8);
        }
    }

    .product-cards-grid {
        @include grid-columns(1fr, 20px, 80px);
        margin: 0 20px;

        img {
            transform: scale(0.9);
            margin-left: -20px;
        }
    }

    .flavours-section {
        margin-right: 20px;

        &-grid {
            @include grid-columns(1fr, 0, 100px);

            .flavour {
                &-pattern {
                    width: $pattern-size-large + 100px;
                    height: $pattern-size-large + 100px;
                }

                h3 {
                    margin-top: 50px;
                }

                .hortela-flavour,
                .vinho-porto-flavour {
                    width: 300px;
                }

                .anis-flavour {
                    width: 280px;
                }

                .toranja-flavour {
                    margin-right: 20px;
                }
            }
        }
    }

    .newsletter-input-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 45px;

        .newsletter-input {
            width: 300px;
        }
    }
}

//SCREEN SIZES SMALLER THAN 320px ----------------------------------------
@include max-extra-small {
    .introduction-text {
        .landing-pattern {
            width: $pattern-size-medium;
            height: $pattern-size-medium;
            top: 20px;
            right: -20px;
        }
    }

    banner-container {
        padding-top: 10px;

        img {
            margin-right: -150px;
            transform: scale(0.6);
        }
    }

    .product-cards-grid {
        img {
            transform: scale(0.8);
            margin-top: 20px;
        }
    }

    .flavours-section {
        margin-right: 20px;

        &-grid {
            @include grid-columns(1fr, 0, 50px);

            .flavour {
                &-pattern {
                    width: $pattern-size-large;
                    height: $pattern-size-large;
                }

                .hortela-flavour,
                .vinho-porto-flavour,
                .anis-flavour,
                .toranja-flavour,
                .chocolate-flavour {
                    width: 250px;
                }

                .toranja-flavour {
                    margin-right: 20px;
                }
            }
        }
    }

    .newsletter {
        margin: 0 20px;

        p {
            width: 80%;
        }

        &-input-wrapper {
            .newsletter-input {
                width: 250px;
            }
        }
    }
}

//-------------------------------------------------------------------------
//GENERAL STYLES FOR ALL SCREEN SIZES -------------------------------------
.introduction-text {
    position: relative;
    overflow: hidden;
    padding-top: 160px;

    .description {
        margin-top: 20px;
    }

    .introduction-slogan {
        color: $laranja;
        text-transform: uppercase;
        letter-spacing: 0.13em;
        margin-bottom: 10px;
    }

    .landing-pattern {
        @extend .rotate30;
        position: absolute;
        opacity: 5%;
    }
}

//ROTATING BANNER FOR PRODUCTS ON LANDING PAGE -----------------------------
.banner-container {
    overflow: hidden;
    margin: 20px auto 70px;

    .product-banner {
        display: flex;
        flex-direction: row;

        img {
            @include transition(0.2s);

            &:hover {
                transform: scale(1.03);
                cursor: pointer;
                @include transition(0.2s);
            }
        }

        .first-product {
            animation: bannermove 35s linear infinite;
            animation-fill-mode: forwards;
        }
    }
}

.best-sellers-section {
    margin: 130px 0px;
}

//FLAVOURS SECTION -----------------------------------------------------------------------------
.flavours-section {
    h2 {
        display: block;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 100px;
    }

    &-grid {
        //SINGLE FLAVOURS
        //flavour class from the flavour.js component
        .flavour {
            display: block;
            margin: 0 auto;

            h3 {
                text-align: center;
            }

            &-patterns-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &-pattern {
                @extend .rotate20;
                position: absolute;
                z-index: -1;
                opacity: 20%;
                margin-top: 100px;
            }

            //images for flavours
            .flavour-image {
                display: block;
                margin: 0 auto;
                z-index: 1;
                @include transition(0.3s);

                &:hover {
                    transform: scale(1.1);
                    @include transition(0.3s);
                }
            }

            .vinho-porto-flavour {
                margin: 10px 0px;
            }

            .chocolate-flavour {
                margin-top: 30px;
                margin-bottom: 20px;
            }

            .eucalipto-flavour {
                width: 300px;
                margin-top: -90px;
            }

            .svg-chocolate {
                background-repeat: no-repeat;
            }
        }
    }
}

//NEWSLETTER --------------------------------------------------------------------------------------
.newsletter {
    margin-top: 130px;
    margin-bottom: 100px;

    h2 {
        text-align: center;
    }

    &-description {
        display: block;
        margin: 0 auto;
        text-align: center;
        margin-top: 20px;
    }

    &-input-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 45px;
    }

    &-input {
        width: 400px;
        margin-right: 20px;
    }

    .button {
        margin-top: 18px;
    }
}

//DISPLAY PRODUCT PATTERNS WHEN HOVERING IMAGE
$product-patterns: (
    original: url('../../images/patterns/original.svg'),
    hortela: url('../../images/patterns/hortela.svg'),
    anis: url('../../images/patterns/anis.svg'),
    eucalipto: url('../../images/patterns/eucalipto.svg'),
    vinhoporto: url('../../images/patterns/vinhoporto.svg'),
    toranja: url('../../images/patterns/toranja.svg'),
    original-berry: url('../../images/patterns/Baga_original.svg'),
    chocolate: url('../../images/patterns/chocolate.svg'),
);

@each $product, $pattern in $product-patterns {
    .#{$product}-hover {
        position: relative;
        display: block;

        &:hover .banner-product-hover {
            background-image: $pattern;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
        }
    }
}

.banner-product-hover {
    display: none;
    position: absolute;
    z-index: 2;
    width: $pattern-size-small;
    height: $pattern-size-small;
    top: -$pattern-size-small;
    right: $pattern-size-small;
}

.resized-berry-images {
    transform: scale(0.8);
    margin-left: -80px;
    margin-top: -40px;
}

//KEYFRAMES FOR MOVING PRODUCT BANNER -------------------------------------------------------------
@keyframes bannermove {
    from {
        margin-left: 50px;
    }

    to {
        margin-left: -5850px;
    }
}
