.terms-conditions-wrapper {
    width: 60%;
    padding-top: 150px;
    margin-left: 60px;

    .terms-conditions {
        h3 {
            color: $azul-escuro;
            margin-bottom: 5px;
        }

        section {
            margin-bottom: 30px;
        }
    }
}
