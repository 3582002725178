.sidebar {
    width: 240px;
    position: sticky;
    top: 100px;
    margin-bottom: 50px;
    z-index: 1;
    background: $branco;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: $radius-20;
    overflow-x: hidden;
    padding: 30px 0;

    a {
        @extend .small-text-light;
        color: $azul-escuro;
        padding: 5px 30px;
        text-decoration: none;
        display: block;

        &:nth-child(2),
        &:nth-child(9),
        &:nth-child(11),
        &:nth-child(13),
        &:nth-child(14) {
            &:hover,
            &:focus {
                @extend .small-text-semibold;
                color: $laranja;
            }
        }

        &:nth-child(3) {
            &:hover,
            &:focus {
                @extend .small-text-semibold;
                color: $hortela;
            }
        }

        &:nth-child(4) {
            &:hover,
            &:focus {
                @extend .small-text-semibold;
                color: $anis-chocolate;
            }
        }

        &:nth-child(5) {
            &:hover,
            &:focus {
                @extend .small-text-semibold;
                color: $eucalipto;
            }
        }

        &:nth-child(6) {
            &:hover,
            &:focus {
                @extend .small-text-semibold;
                color: $vinho-porto;
            }
        }

        &:nth-child(7) {
            &:hover,
            &:focus {
                @extend .small-text-semibold;
                color: $toranja;
            }
        }

        &:nth-child(8) {
            &:hover,
            &:focus {
                @extend .small-text-semibold;
                color: $chocolate;
            }
        }

        &:nth-child(12) {
            &:hover,
            &:focus {
                @extend .small-text-semibold;
                color: $chocolate;
            }
        }
    }

    p {
        color: $azul-escuro;
        padding: 3px 30px;
    }
}
//--------------------------------------------------------------------

//HORIZONTAL SCROLLABLE MENU WHEN SIDEBAR IS HIDDEN
.scrollable-menu {
    background-color: none;

    &-title {
        @extend .card-italic-text;
        color: $azul-escuro;
        width: 20%;
        text-align: center;
        padding-top: 14px;
        height: 55px;
        background-color: rgba($cinza, 0.3);
    }

    .nav {
        width: 80%;
        height: 55px;
        background-color: $azul-escuro;
        box-shadow: none;

        &-item {
            @extend .card-default-text;
            color: $branco;
            padding: 15px;
            display: inline-block;
            vertical-align: middle;

            &:not(:last-child) {
                border-right: 1px solid rgba($cinza, 0.2);
            }

            &:hover {
                @extend .card-italic-text;
            }
        }
    }

    .scrollable-menu-title,
    .vertical-align-middle {
        display: inline-block;
        vertical-align: middle;
    }
}

.scroll {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
        display: none;
    }
}

@include max-large {
    .scrollable-menu {
        &-title {
            width: 30%;
        }
        .nav {
            width: 70%;
        }
    }
}
