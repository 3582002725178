.details-heading {
    width: 100%;
    height: 170px;
}

@include max-large {
    .details-heading {
        h2 {
            padding-left: 330px;
        }
    }
}

@include max-large {
    .details-heading {
        height: 120px;

        h2 {
            padding-left: 50px;
        }
    }
}
