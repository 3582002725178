@include extra-large {
    .headings-wrapper-contacts {
        padding-bottom: 30px;
    }

    .contacts-grid {
        display: flex;
        flex-direction: row;
        margin-bottom: 150px;

        .brand-contacts {
            animation-name: move-contacts;
            animation-duration: 2s;
            animation-fill-mode: forwards;
        }

        .contact-card {
            margin-left: 50px;

            &-button {
                .btn--large {
                    width: 430px;
                }
            }
        }

        .input-width,
        .textarea-size {
            width: 430px;
        }

        .textarea-size {
            height: 150px;
        }

        //svg patterns size & positions
        .base-pattern {
            width: $pattern-size-large;
            height: $pattern-size-large;
        }

        .top-pattern {
            left: -$pattern-size-large / 2;
            top: -$pattern-size-large / 4;
            right: -$pattern-size-large / 4;
        }

        .bottom-pattern {
            left: $pattern-size-large / 2;
            top: $pattern-size-large * 2;
        }
    }
}

//SCREEN SIZES BIGGER THAN 992px AND SMALLER THAN 1200px -------------------------------
@include min-large-max-extra-large {
    .headings-wrapper-contacts {
        padding-bottom: 30px;
    }

    .contacts-grid {
        display: flex;
        flex-direction: row;
        margin-bottom: 150px;

        .contact-card {
            margin-left: 100px;
            margin-right: 50px;

            &-button {
                .btn--large {
                    width: 430px;
                }
            }
        }

        .input-width,
        .textarea-size {
            width: 430px;
        }

        .textarea-size {
            height: 150px;
        }

        .base-pattern {
            width: $pattern-size-medium;
            height: $pattern-size-medium;
        }

        .top-pattern {
            display: none;
        }

        .bottom-pattern {
            top: $pattern-size-large * 2;
            left: -$pattern-size-large * 3;
        }
    }
}

//SCREEN SIZES BETWEEN 768px AND 992px SCREEN SIZES -----------------------------------------
@include min-medium-max-large {
    .contacts-grid {
        display: flex;
        flex-direction: column;

        .contact-card {
            margin: 50px 220px 100px 50px;

            &-button {
                .btn--large {
                    width: 400px;
                }
            }
        }

        .input-width,
        .textarea-size {
            width: 430px;
        }

        .textarea-size {
            height: 150px;
        }

        .base-pattern {
            width: $pattern-size-medium;
            height: $pattern-size-medium;
        }

        .top-pattern {
            margin-top: -700px;
            margin-left: 450px;
        }

        .bottom-pattern {
            display: none;
        }
    }
}

//SCREEN SIZES SMALLER THAN 768px -----------------------------------------
@include max-medium {
    .headings-wrapper-contacts,
    .brand-contacts {
        margin-right: 50px;
    }

    .input-width,
    .textarea-size {
        width: 250px;
    }

    .contact-card {
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 50px;

        &-button {
            .btn--large {
                width: 250px;
            }
        }
    }

    .textarea-size {
        height: 100px;
    }
}

//SCREEN SIZES SMALLER THAN 320px -----------------------------------------
@include max-extra-small {
    .headings-wrapper-contacts,
    .brand-contacts {
        margin-right: 30px;
    }

    .contacts-grid {
        display: flex;
        flex-direction: column;

        .contact-card {
            margin-left: 30px;
            margin-right: 30px;
            margin-bottom: 50px;

            &-button {
                .btn--large {
                    width: 180px;
                }
            }
        }

        .input-width,
        .textarea-size {
            width: 180px;
        }

        .textarea-size {
            height: 100px;
        }

        .top-pattern,
        .bottom-pattern {
            display: none;
        }
    }
}

//GENERAL STYLES FOR ALL SCREEN SIZES -------------------------------------
.headings-wrapper-contacts {
    margin-left: 50px;
    padding-top: 120px;
}

.base-pattern {
    z-index: -1;
    position: relative;
}

//class from contact.js component
.single-contact-component {
    margin-left: 50px;
    margin-top: 50px;

    .contact-icon {
        font-size: 5rem;
        color: $azul-escuro;
        margin-top: 10px;
        transition: 0.3s all;

        &:hover {
            color: $laranja;
            cursor: pointer;
        }
    }
}

//card with textfields to submit message
.contact-card {
    background: rgba($branco, 0.8);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    border-radius: $radius-20;
    padding: $card-content-padding;
    margin-top: 50px;

    &-item,
    &-button {
        margin-top: 20px;
    }
}

@keyframes move-contacts {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(80px);
        animation-play-state: paused;
    }
}
