//BREADCRUMB -------------------------------------------------
ul.breadcrumb {
    @extend .small-text;
    color: $cinza;
    //padding: 10px 16px;
    list-style: none;

    li {
        display: inline;

        a {
            color: $azul-escuro;
            text-decoration: none;

            &:hover {
                color: $laranja;
            }
        }
    }

    //símbolo da barra antes de cada item da lista
    li + li:before {
        padding: 4px;
        color: $azul-escuro;
        content: '/\00a0';
    }
}
