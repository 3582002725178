// TOPNAV ----------------------------------------------------
nav {
    @extend .navbar-title;
    width: 100vw;
    background: rgba($branco, 0.9);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5px);
    height: 70px;
    z-index: 999;

    .logo-nav {
        float: left;
        padding-top: 7px;
        padding-left: 35px;
        padding-right: 20px;

        svg {
            &:hover {
                fill: $laranja;
                transition: all 0.2s;
            }
        }
    }

    ul {
        li {
            @extend .padding20-20;
            display: inline-block;
            margin: 0 5px;
        }

        a {
            color: $azul-escuro;
            text-decoration: none;
            text-align: center;

            &:hover {
                color: $laranja;
            }

            &:focus {
                @extend .navbar-title-active;
                color: $laranja;
                outline: none;
            }

            &.active {
                color: $laranja;
            }
        }
    }

    .checkbtn {
        font-size: 3.5rem;
        color: $azul-escuro;
        float: right;
        line-height: 190%;
        margin-right: 40px;
        cursor: pointer;
        display: none;
    }

    #check {
        display: none;
    }

    .search-container {
        float: right;
        padding-top: 7px;
        padding-right: 50px;

        button {
            padding: 16px 10px;
            margin-left: 3px;
            font-size: 18px;
            border: none;
            background: none;
            cursor: pointer;
        }
    }
}

@media (max-width: $screen-large) {
    nav {
        z-index: 9999;
        .checkbtn {
            display: block;
        }

        ul {
            position: fixed;
            width: 100%;
            height: 100vh;
            top: 70px;
            left: -100%;
            transition: all 0.3s;
            background-color: $branco;

            li {
                display: block;
                font-family: Literata;
                font-style: italic;
                font-weight: 300;
                font-size: 2.5rem;
                line-height: 115%;
                letter-spacing: 0.01em;
                font-feature-settings: 'tnum' on, 'lnum' on;
                text-align: left;
                padding-left: 60px;
            }
        }

        #check:checked ~ ul {
            left: 0;
        }

        .search-container {
            float: left;
            padding-left: 60px;
        }
    }
}

@include extra-small {
    .logo-nav {
        padding-left: 40px;
    }
}

@include max-extra-small {
    .logo-nav {
        width: 100px;
    }
}

//DROPDOWN --------------------------------------------
.dropdown {
    float: left;
    overflow: hidden;

    &-btn {
        border: none;
        outline: none;
        background-color: inherit;
    }

    &:hover &-content {
        display: block;
    }

    &-content {
        padding: 10px;
        display: none;
        position: absolute;
        background-color: $branco;
        width: 250px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        border-radius: $radius-10;
        z-index: 1;

        a {
            float: none;
            color: $azul-escuro;
            padding: 10px 20px;
            text-decoration: none;
            display: block;
            text-align: left;
        }
    }
}
