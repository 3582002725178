//BASE BUTTON -------------------------------------------------
.btn {
    @extend .button-text;
    cursor: pointer;
    border: none;
    border-radius: $radius-15;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    transition: 0.1s all;

    &:hover {
        box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);
        transform: scale(1.02);
    }

    &:focus {
        border: 2px solid $azul-escuro;
        box-shadow: 0px 5px 8px rgba($azul-escuro, 0.2);
    }

    &:active {
        box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3),
            inset 7px 8px 10px rgba(200, 200, 200, 0.1);
    }

    &:disabled {
        background-color: $cinza;
        box-shadow: none;
        cursor: no-drop;
    }

    //BUTTON STYLES -------------------------------------------------
    //primary button
    &--primary {
        background-color: $azul-escuro;
        color: $branco;
    }

    //buttons for physalina's products
    &--original {
        background-color: $laranja;
        color: $branco;
    }

    &--toranja {
        background-color: $toranja;
        color: $branco;
    }

    &--vinho-porto {
        background-color: $vinho-porto;
        color: $branco;
    }

    &--anis-chocolate {
        background-color: $anis-chocolate;
        color: $branco;
    }

    &--chocolate {
        background-color: $chocolate;
        color: $branco;
    }

    &--hortela {
        background-color: $hortela;
        color: $branco;
    }

    &--eucalipto {
        background-color: $eucalipto;
        color: $branco;
    }

    //secondary button
    &--secondary {
        @extend .btn--primary;
        color: $azul-escuro;
        background-color: transparent;
        border: 2px solid $azul-escuro;
        box-shadow: none;

        &:hover {
            border: 3px solid $azul-escuro;
            box-shadow: none;
        }

        &:active {
            border: 3px solid #0f2043;
            box-sizing: border-box;
            box-shadow: 0px 0px 4px #0f2043;
        }

        &:disabled {
            background-color: transparent;
            color: $cinza;
            border: 2px solid $cinza;
            box-shadow: none;
            cursor: no-drop;
        }
    }

    //BUTTON SIZES -------------------------------------------------
    &--normal {
        padding: 15px 25px;
    }

    &--medium {
        padding-left: 35px;
        padding-right: 35px;
    }

    &--large {
        padding-left: 45px;
        padding-right: 45px;
    }
}
