footer {
    width: 100%;
    background: $branco;
    color: $azul-escuro;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    display: block;

    #physalina-logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-top: 40px;
    }

    a {
        @extend .normal-text;
        display: block;
        color: $azul-escuro;
        margin-top: 10px;
        text-decoration: none;
        transition: all 0.3s;

        &:hover {
            color: $laranja;
            margin-left: 5px;
            transition: all 0.3s;
        }
    }

    .footer-content {
        width: 100%;
        padding-bottom: 20px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;

        .footer-section {
            flex: 1;
            //padding: 25px;

            p {
                margin-top: 5px;
            }
        }
    }

    .footer-bottom {
        @extend .small-text;
        background-color: rgba($color: $cinza, $alpha: 0.5);
        color: $azul-escuro;
        width: 100%;
        height: 30px;
        text-align: center;
        bottom: 0;
        left: 0;
        padding-top: 5px;
    }
}

@media screen and (max-width: $screen-large) {
    .footer {
        width: 100%;
    }

    .footer-content {
        width: 40%;

        .footer-section {
            padding: 15px 25px;
        }
    }
}

@include extra-small {
    .footer-section {
        padding: 25px;
    }
}
