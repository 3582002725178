.dropdown-wrapper {
    position: relative;
    padding: 3px;
    margin-right: 30px;

    .filter-input {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        width: 200px;
        padding: 0 15px;
        border: 1px solid $cinza;
        border-radius: $radius-10;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:focus {
            border: 1px solid #ff7800;
            box-shadow: 0px 0px 3px rgba(255, 120, 0, 0.8);
            border-radius: $radius-10;
            outline: none;
            transition: all ease-in-out 0.1s;
        }

        &:hover {
            cursor: pointer;
        }

        i {
            padding-bottom: 3px;
        }
    }

    .filter-input-content {
        position: absolute;
        width: 200px;
        padding: 0 15px;
        margin-top: 5px;
        border: 1px solid $cinza;
        border-radius: $radius-10;
        background-color: $branco;
        z-index: 3;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

@include large {
    .dropdown-wrapper {
        float: right;
    }
}

@include max-large {
    .dropdown-wrapper {
        margin-top: 30px;
    }
}
