//CARD BASE FOR EVERY CARDS USED --------------------------------------
.card {
    width: auto;
    padding-bottom: 30px;
    left: 0;
    top: 0;
    bottom: 0;
    background: $branco;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.12);
    border-radius: $radius-15;

    //titles for every card
    .title {
        @extend .large-text;
        color: $azul-escuro;
        padding: $card-content-padding 0 0 $card-content-padding;
    }

    //cards for the about page
    &-about-brand {
        width: auto;
        background: rgba($branco, 0.9);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(3px);
        border-radius: $radius-20;
        padding: $card-about-padding;
    }

    //CARD NUTRITIONAL VALUES ---------------------------------------
    .nutricional-content {
        padding: 0 0 0 $card-content-padding;

        .energy,
        .lipids,
        .hydrates,
        .fiber,
        .proteins,
        .salt {
            padding-top: 13px;

            span {
                color: $azul-escuro;

                &:nth-child(1) {
                    @extend .navbar-title;
                }

                &:nth-child(2) {
                    @extend .medium-text;
                }
            }
        }

        .lipids,
        .hydrates {
            span:nth-child(3) {
                @extend .medium-text;
            }
        }
    }

    //CARD INGREDIENTES ---------------------------------------
    .ingredients-content {
        padding: 0 0 0 $card-content-padding;

        .physalis,
        .sugar,
        .salt {
            padding-top: 20px;

            p {
                @extend .card-default-text;
            }
        }

        .extra-ingredient {
            padding-top: 20px;

            p {
                @extend .card-default-text;
            }
        }

        .extra-info {
            padding-top: 20px;

            p {
                @extend .medium-text;
                color: rgba($azul-escuro, 0.5);
            }
        }
    }
}

//CARD BRAND LOGOTYPES ------------------------------------------------
.logo-card {
    @extend .card;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: $card-content-padding;
    @include transition(0.1s);

    &:hover {
        cursor: pointer;
        transform: scale(1.03);
        @include transition(0.2s);
    }
}

//CARD DISABLED ------------------------------------------------------
.card-disabled {
    @extend .card;
    color: $cinza;
    background-color: rgba($color: $cinza, $alpha: 0.2);
    box-shadow: none;
}

//CARD FOR PRODUCTS ON LANDING PAGE -----------------------------------
.card-product {
    background: $branco;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.12);
    border-radius: $radius-20;
    padding: $card-content-padding $card-content-padding 10px;
    margin-top: -50px;

    &:hover {
        cursor: pointer;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
        @include transition(0.3s);

        .card-product-name {
            margin-left: 15px;
            @include transition(0.2s);
        }
    }

    .card-product-layout {
        display: flex;
        flex-direction: row;
    }

    .card-product-name {
        margin-bottom: 20px;
        @include transition(0.3s);
    }

    .card-product-image {
        margin-right: 30px;

        .resized-product-image {
            width: 130px;
        }
    }
}
