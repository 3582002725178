// HEADINGS -------------------------------
h1,
.h1 {
    font-family: Literata;
    font-style: normal;
    font-weight: bold;
    //font-size: 6.4rem;
    line-height: 110%;
    letter-spacing: 0.01em;
    font-feature-settings: 'pnum' on, 'onum' on;
    color: $azul-escuro;

    @include extra-small {
        @include calc-font-size(50, 100, 5, 6);
    }
}

h2,
.h2 {
    font-family: Literata;
    font-style: normal;
    font-weight: 600;
    //font-size: 5rem;
    line-height: 110%;
    font-feature-settings: 'pnum' on, 'onum' on;
    color: $azul-escuro;

    @include extra-small {
        @include calc-font-size(50, 100, 3.5, 4.2);
    }
}

h3,
.h3 {
    font-family: Literata;
    font-style: italic;
    font-weight: 300;
    //font-size: 4rem;
    line-height: 125%;
    letter-spacing: 0.01em;
    font-feature-settings: 'tnum' on, 'lnum' on;

    @include extra-small {
        @include calc-font-size(50, 100, 2.5, 3);
    }
}

.large-text {
    font-family: Livvic;
    font-style: normal;
    font-weight: 600;
    //font-size: 2.2rem;
    line-height: 130%;
    letter-spacing: 0.02em;

    @include extra-small {
        @include calc-font-size(50, 100, 1.9, 2);
    }
}

.medium-text {
    font-family: Livvic;
    font-style: normal;
    font-weight: normal;
    //font-size: 1.8rem;
    line-height: 140%;
    letter-spacing: 0.01em;
    font-feature-settings: 'tnum' on, 'onum' on;
    color: $azul-escuro;

    @include extra-small {
        @include calc-font-size(50, 100, 1.5, 1.7);
    }
}

// TEXT --------------------------------
.normal-text {
    font-family: Livvic;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 130%;
    letter-spacing: 0.01em;
    font-feature-settings: 'tnum' on, 'onum' on;
    color: $azul-escuro;
}

.small-text {
    font-family: Livvic;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 140%;
    letter-spacing: 0.01em;
    font-feature-settings: 'tnum' on, 'onum' on;
}

.small-text-light {
    @extend .small-text;
    font-weight: 400;
}

.small-text-semibold {
    font-family: Livvic;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 140%;
    letter-spacing: 0.01em;
    font-feature-settings: 'tnum' on, 'onum' on;
}

// BUTTON TEXT -------------------------------
.button-text {
    font-family: Livvic;
    font-style: normal;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 130%;
    letter-spacing: 0.02em;
    font-feature-settings: 'tnum' on, 'onum' on;
    color: $azul-escuro;
}

// TEXT FIELDS -------------------------------
.label-text {
    font-family: Livvic;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 130%;
    letter-spacing: 0.01em;
    font-feature-settings: 'tnum' on, 'onum' on;
    color: $azul-escuro;
    padding-left: 15px;
}

.placeholder-text {
    font-family: Livvic;
    font-style: normal;
    font-weight: normal;
    line-height: 130%;
    font-feature-settings: 'tnum' on, 'onum' on;
    font-size: 1.6rem;
}

.error-text {
    font-family: Livvic;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 130%;
    letter-spacing: 0.01em;
    font-feature-settings: 'tnum' on, 'onum' on;
    color: $laranja;
}

// NAVBAR TEXT -------------------------------
.navbar-title {
    font-family: Literata;
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 120%;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $azul-escuro;
}

.navbar-title-active {
    font-family: Literata;
    font-style: italic;
    font-weight: bold;
    font-size: 2rem;
    line-height: 120%;
    letter-spacing: 0.03em;
    font-feature-settings: 'tnum' on, 'onum' on;
    //color: $laranja;
}

.card-default-text {
    @extend .navbar-title;

    @include extra-small {
        @include calc-font-size(50, 100, 1.7, 1.9);
    }
}

.card-italic-text {
    @extend .navbar-title-active;

    @include extra-small {
        @include calc-font-size(50, 100, 1.7, 1.9);
    }
}

// GLOBAL -------------------------------
::-moz-selection {
    // for Firefox
    color: $azul-escuro;
    background: $cinza;
}

::selection {
    color: $azul-escuro;
    background: $cinza;
}
